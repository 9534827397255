/* Global Mixins and variables */
.clearfix:after {
  content: '';
  display: block;
  clear: both;
  height: 0;
}
@-moz-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-moz-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.AcceptLinkPage,
.RedeemLegacyGiftPage,
.RegisterWithSubUserTokenPage,
.CampCouponPage {
  padding: 8rem;
  background: #f9f6ef;
}
@media (max-width: 991px) {
  .AcceptLinkPage,
  .RedeemLegacyGiftPage,
  .RegisterWithSubUserTokenPage,
  .CampCouponPage {
    padding: 1rem;
  }
}
.AcceptLinkPage .title,
.RedeemLegacyGiftPage .title,
.RegisterWithSubUserTokenPage .title,
.CampCouponPage .title {
  font-size: 3em;
  font-weight: 600;
  color: #745a9e;
  font-family: 'Zona Pro', 'Verdana';
  text-align: center;
}
@media (max-width: 991px) {
  .AcceptLinkPage .title,
  .RedeemLegacyGiftPage .title,
  .RegisterWithSubUserTokenPage .title,
  .CampCouponPage .title {
    font-size: 2.2em;
    margin-left: 1rem;
  }
}
.AcceptLinkPage .message,
.RedeemLegacyGiftPage .message,
.RegisterWithSubUserTokenPage .message,
.CampCouponPage .message {
  color: black;
  max-width: 76rem;
  border-radius: 1rem;
  background: #fff;
  margin: 5rem auto;
  font-weight: 600;
  padding: 2rem;
}
.AcceptLinkPage .Form,
.RedeemLegacyGiftPage .Form,
.RegisterWithSubUserTokenPage .Form,
.CampCouponPage .Form {
  margin: 0 auto;
  text-align: left;
  width: 100%;
  max-width: 50rem;
  margin-top: 5rem;
}
.AcceptLinkPage .Form label,
.RedeemLegacyGiftPage .Form label,
.RegisterWithSubUserTokenPage .Form label,
.CampCouponPage .Form label {
  width: 100%;
}
.AcceptLinkPage .Form .Input,
.RedeemLegacyGiftPage .Form .Input,
.RegisterWithSubUserTokenPage .Form .Input,
.CampCouponPage .Form .Input {
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.AcceptLinkPage .Form .Input.giftCode input,
.RedeemLegacyGiftPage .Form .Input.giftCode input,
.RegisterWithSubUserTokenPage .Form .Input.giftCode input,
.CampCouponPage .Form .Input.giftCode input {
  font-family: 'Inconsolata', 'Courier';
  font-size: 2rem;
  letter-spacing: 0.4rem;
}
.AcceptLinkPage .Form .RoundButton,
.RedeemLegacyGiftPage .Form .RoundButton,
.RegisterWithSubUserTokenPage .Form .RoundButton,
.CampCouponPage .Form .RoundButton {
  width: 100%;
}
.AcceptLinkPage > .content > .buttonWrapper,
.RedeemLegacyGiftPage > .content > .buttonWrapper,
.RegisterWithSubUserTokenPage > .content > .buttonWrapper,
.CampCouponPage > .content > .buttonWrapper {
  text-align: center;
}
